<template>
    <div>
        <div id="speakers0"></div>
        <search-header :currentActive="6"></search-header>
        <img src="@/assets/img/s_banner.png" style="width:100%">
        <div class="nav-header">
            <div class="container p-0">
                <div class="row m-0">
                    <div @click="scrollToBottom('#speakers0')" class="col my-3 item" :class="{'active' : current == 0}">展览首页</div>
                    <div @click="scrollToBottom('#speakers1')" class="col my-3 ml-3 item" :class="{'active' : current == 1}">优质土地</div>
                    <div @click="scrollToBottom('#speakers2')" class="col my-3 ml-3 item" :class="{'active' : current == 2}">优质服务商</div>
                    <div @click="scrollToBottom('#speakers3')" class="col my-3 ml-3 item" :class="{'active' : current == 3}">行业资讯</div>
                    <div @click="scrollToBottom('#speakers4')" class="col my-3 ml-3 item" :class="{'active' : current == 4}">需求大厅</div>
                    <!-- <router-link to="/" target="_blank" class="col my-3 ml-3 item">农机具</router-link> -->
                    <router-link to="/needs/makemaps" target="_blank" class="col my-3 ml-3 item">土地测绘</router-link>
                    <router-link to="/news/detail?id=1429971970109812738" target="_blank" class="col my-3 ml-3 item">赣抚农品</router-link>
                </div>
            </div>
        </div>
        <div id="speakers1"></div>
        <section id="speakers">
            <div class="container">
                <div class="section-header">
                    <div class="d-flex justify-content-center align-items-center">
                        <h2 class="mb-0">优质土地</h2>
                        <router-link to="/supply/index" target="_blank">
                            <span class="ml-4 f-20">more </span><b-icon icon="arrow-right-circle"></b-icon>
                        </router-link>
                    </div>
                    <p class="mt-2">Here are some of our high quality land</p>
                </div>
                <div class="row m-0">
                    <div class="col-12 p-0">
                        <div class="land-nav">
                            <ul>
                                <li class="active"><a href="javascript:;">耕地</a></li>
                                <li class=""><a href="javascript:;">林地</a></li>
                                <li class=""><a href="javascript:;">园地</a></li>
                                <li class=""><a href="javascript:;">草地</a></li>
                                <li class=""><a href="javascript:;">养殖用地</a></li>
                                <li class=""><a href="javascript:;">农房</a></li>
                                <li class=""><a href="javascript:;">其他土地</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-3 p-0" :class="{ 'pl-2': i > 0 }" v-for="(m,i) in landList" :key="m.id">
                        <land :datas="m" :height="200"></land>
                    </div>
                </div>
            </div>
        </section>
        <section id="speakers" class="section-with-bg">
            <div class="container">
                <div class="section-header">
                    <div class="d-flex justify-content-center align-items-center">
                        <h2 class="mb-0">标签找地</h2>
                        <router-link to="/supply/index" target="_blank">
                            <span class="ml-4 f-20">more </span><b-icon icon="arrow-right-circle"></b-icon>
                        </router-link>
                    </div>
                    <p class="mt-2">Here are some of our tag location</p>
                </div>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center align-items-center flex-wrap">
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image yu"></div>
                            </div>
                            <div class="f-14 mt-1">鱼</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image zhu"></div>
                            </div>
                            <div class="f-14 mt-1">猪</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image yumi"></div>
                            </div>
                            <div class="f-14 mt-1">玉米</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image dasuan"></div>
                            </div>
                            <div class="f-14 mt-1">大蒜</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image taozi"></div>
                            </div>
                            <div class="f-14 mt-1">桃子</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image ji"></div>
                            </div>
                            <div class="f-14 mt-1">鸡</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image guangfuyongdi"></div>
                            </div>
                            <div class="f-14 mt-1">光伏用地</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image yingtao"></div>
                            </div>
                            <div class="f-14 mt-1">樱桃</div>
                        </div>
                    </div>
                    <div class="col-12 mt-3 d-flex justify-content-center align-items-center flex-wrap">
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image huolongguo"></div>
                            </div>
                            <div class="f-14 mt-1">火龙果</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image qiukui"></div>
                            </div>
                            <div class="f-14 mt-1">秋葵</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image shuidao"></div>
                            </div>
                            <div class="f-14 mt-1">水稻</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image putao"></div>
                            </div>
                            <div class="f-14 mt-1">葡萄</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image tu"></div>
                            </div>
                            <div class="f-14 mt-1">兔</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image hetao"></div>
                            </div>
                            <div class="f-14 mt-1">核桃</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image lanmei"></div>
                            </div>
                            <div class="f-14 mt-1">蓝莓</div>
                        </div>
                        <div class="tag">
                            <div class="tag-item">
                               <div class="tag-item-image huajiao"></div>
                            </div>
                            <div class="f-14 mt-1">花椒</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="speakers2"></div>
        <section id="speakers">
            <div class="container">
                <div class="section-header">
                    <div class="d-flex justify-content-center align-items-center">
                        <h2 class="mb-0">优质服务商</h2>
                        <router-link to="/servicecentre/index" target="_blank">
                            <span class="ml-4 f-20">more </span><b-icon icon="arrow-right-circle"></b-icon>
                        </router-link>
                    </div>
                    <p class="mt-2">Here are some of our quality service provider</p>
                </div>
                <div class="row">
                    <div v-for="i in 8" :key="i" class="col-3 mb-3 p-0">
                        <div class="row bg-white border rounded m-0" style="width: 96%;overflow: hidden;">
                            <div class="col-12 p-0">
                                <div style="height: 200px;width: 100%;"><img style="width: 100%;" src="@/static/img/s_0.png"/></div>
                            </div>
                            <div class="col-12 p-0">
                                <div class="bg-white p-3">
                                    <div class="text-truncate">江西抚州土地流转服务中心</div>
                                    <div class="mt-1 f-14 text-secondary clearfix">
                                        <div class="float-left">
                                            <span>好评：</span>
                                            <span class="ml-1 text-warning">100%</span>
                                        </div>
                                        <div class="float-right">
                                            <span>经验值：</span>
                                            <span class="ml-1 text-warning">2204</span>
                                        </div>
                                    </div>
                                    <div class="mt-1 f-14 text-secondary clearfix pb-2 border-bottom">
                                        <div class="float-left">
                                        <b-icon icon="person"></b-icon><span class="ml-1">廖金平</span>
                                        </div>
                                        <div class="float-right">
                                        <b-icon icon="phone"></b-icon><span class="ml-1">177765554678</span>
                                        </div>
                                    </div>
                                    <div class="f-14 d-flex justify-content-between" style="margin-top: 12px;">
                                        <b-button size="sm" variant="success">帮我买地</b-button>
										<b-button size="sm" variant="outline-success">帮我找地</b-button>
										<b-button size="sm" variant="warning">业务咨询</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="speakers3"></div>
        <section id="speakers" class="section-with-bg">
            <div class="container">
                <div class="section-header">
                    <div class="d-flex justify-content-center align-items-center">
                        <h2 class="mb-0">行业资讯</h2>
                        <router-link to="/news" target="_blank">
                            <span class="ml-4 f-20">more </span><b-icon icon="arrow-right-circle"></b-icon>
                        </router-link>
                    </div>
                    <p class="mt-2">Here are some of our industry information</p>
                </div>
                <div v-if="displayList.length == 3" class="row">
                    <div class="col-4" v-for="m in displayList" :key="m.id">
                        <div class="border-bottom pb-2 mb-3">
                            <span class="h5">{{m.text}}</span>
                        </div>
                        <div v-if="m.text == '热门图文'">
                            <div style="position: relative;">
                                <van-swipe ref="swipe" :autoplay="autoplay" @change="onSwipeChange" :show-indicators="false" indicator-color="white">
                                    <van-swipe-item v-for="n in m.thumbList" :key="n.id">
                                        <router-link :to="'/news/detail?id=' + n.id" target="_blank">
                                            <van-image width="350" height="300" fit="cover"  lazy-load :src="n.thumbnail" />
                                        </router-link>
                                        <div class="text-truncate news-title">{{n.title}}</div>
                                    </van-swipe-item>
                                </van-swipe>
                                
                                <a href="javascript:;" @click="onPrev" class="carousel-control-prev height-300"><span class="carousel-control-prev-icon"></span></a>
                                <a href="javascript:;" @click="onNext" class="carousel-control-next height-300"><span class="carousel-control-next-icon"></span></a>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                            <div class="col-4 height-70">
                                <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">
                                <img class="w-100" :src="m.thumbList[0].thumbnail" style="height:70px"/>
                                </router-link>
                            </div>
                            <div class="col-8 p-0">
                                <router-link :to="'/news/detail?id=' + m.thumbList[0].id" target="_blank">
                                <div class="text-truncate width-250">{{m.thumbList[0].title}}</div>
                                </router-link>
                                <div class="text-secondary width-250 ellipsis-2" v-html="m.thumbList[0].content"></div>
                            </div>
                            </div>
                            <div class="text-truncate" :class="{ 'mt-3' : j == 0,  'mt-2' : j > 0}" v-for="s,j in m.list" :key="s.id"><router-link :to="'/news/detail?id=' + s.id" target="_blank">{{s.title}}</router-link></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="speakers4"></div>
        <section id="speakers">
            <div class="container mb-3">
                <div class="section-header">
                    <div class="d-flex justify-content-center align-items-center">
                        <h2 class="mb-0">需求大厅</h2>
                        <router-link to="/needs" target="_blank">
                            <span class="ml-4 f-20">more </span><b-icon icon="arrow-right-circle"></b-icon>
                        </router-link>
                    </div>
                    <p class="mt-2">These are some of our clients looking for land</p>
                </div>
                <div class="row justify-content-between">
                    <div class="col-8">
                        <div class="row">
                            <div class="col-4 p-0 pr-2" v-for="m,i in needList.records" :key="i">
                                <div class="border p-2 rounded" :class="{'mb-3' : i <= 11}">
                                    <router-link :to="'/needs/detail?id=' + m.id" target="_blank"><h6 class="text-truncate">{{m.title}}</h6></router-link>
                                    <div class="mt-2 f-14 text-secondary clearfix">
                                        <div v-if="m.year" class="float-left">
                                            <div v-if="m.minPrice > 0 && m.maxPrice > 0">
                                                <span v-if="m.minPrice > 0 && m.maxPrice > 0" class="text-warning">{{m.minPrice }} ~ {{m.maxPrice}}</span>
                                                <span class="ml-2 text-secondary">{{getPriceUnitValue(m.priceUnit)}}</span>
                                            </div>
                                            <div v-else>
                                                <span class="text-theme">价格面议</span>
                                            </div>
                                        </div>
                                        <div class="float-right">
                                            <span class="text-warning">{{m.year}}</span>
                                            <span class="text-secondary ml-1">年</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 p-0 border rounded">
                        <div class="m-4 pb-4 text-center h4 border-bottom">30秒快速免费发布信息</div>
                        <div class="m-4">
                            <div class="screen text-center">
                                <a
                                  class="p-2 px-3 mr-3"
                                  :class="{ active: tools.typeId == m.id }"
                                  @click="tools.typeId = m.id"
                                  href="javascript:;"
                                  v-for="(m, i) in tools.iType"
                                  :key="i"
                                  >{{ m.text }}</a
                                >
                            </div>
                            <div class="mt-4">
                                <div class="mb-1">发布地区：</div>
                                <div class="w-100 areabox box">
                                    <input
                                      @click="tools.isAreaShow = !tools.isAreaShow"
                                      class="form-control ellipsis cursor-hand"
                                      :value="tools.areaValue"
                                      type="text"
                                      placeholder="选择地区"
                                      readonly="readonly"
                                    />
                                    <em>
                                      <b-icon  @click="tools.isAreaShow = !tools.isAreaShow"  icon="chevron-down"></b-icon>
                                    </em>
                                    <city
                                      v-model="tools.isAreaShow"
                                      @onChoose="onChooseNeedsArea"
                                    ></city>
                                  </div>
                            </div>
                            <div class="mt-3">
                                <div class="mb-1">土地用途：</div>
                                <div class="box">
                                    <useland :width="330" @onChange="onUseLandChange"></useland>
                                </div>
                            </div>
                            <b-button
                                class="mt-4 w-100 text-white"
                                @click="onFindNeeds"
                                variant="success"
                                >免费发布信息</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <map-footer></map-footer>
    </div>
</template>

<script>
    import land from "@/components/base/land.vue";
    import city from "@/components/city/city.vue";
    import useland from "@/components/useland/useland.vue";
    import { mapGetters } from "vuex";
    export default {
        components: {
            land, city, useland
        },
        data() {
            return {
                current: 0,
                autoplay:5000,
                landList: [],
                needList: [],
                displayList:[],
                tools: {
                    iType: [
                    {
                        id: 1,
                        text: "发布土地",
                    },
                    {
                        id: 2,
                        text: "登记需求",
                    },
                    ],
                    isAreaShow: false,
                    areaValue: "",
                    typeId: 1,
                    area: null,
                    useLand: null,
                    areaRangeP: null,
                    areaRangeM: null,
                },
                metreArray: ["商业及住宅", "公共用地", "农房"],
            }
        },
        computed: {
            ...mapGetters(["area", "dict"]),
            getAreaValue()	{
				return function(code) {
					let value = '';
					if(this.area) {
						let a = this.area.area.find(x => x.code == code)
						if(a) {
							value = a.name;
							let c = this.area.city.find(x => x.code == a.parentCode);
							if(c) {
								value = value + ' ' + c.name;
								let p = this.area.province.find(x => x.code == c.parentCode);
								if(p) {
									value = value + ' ' + p.name;
								}
							}
						}
					}
					return value.replace(' 市辖区','')
				}
			},
			getChildUseLandList() {
				let c = this.dict.landUseType.find(x=> x.id == this.useLandClassId);
				if (c)  {
					return c.children;
				} 
				return []
			},
			useLandClassValue() {
				let c = this.dict.landUseType.find(x=> x.id == this.useLandClassId);
				if (c)  {
					return c.text;
				} 
				return ''
			},
			getPriceUnitValue () {
				return function(id) {
					if(this.dict && this.dict.priceUnit) {
						let m = this.dict.priceUnit.find(x => x.id == id)
						if(m) {
							return m.text;
						}
					}
					return ''
				}
			},
        },
        watch: {
            'dict.newsDisplay': 'getDisplayList'
        },
        mounted(){
            window.addEventListener('scroll',this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
        },
        methods: {
            getResembleLand() {
                this.$http.post(this.$api.supply.getNeedsForLetList, { param: {},
                size: 8,
                current: 1}).then(res => {
                    this.landList = res.result.records.map(x => {
                        let f = x.userNeedsFiles == null ? null : x.userNeedsFiles.find(y => y.uploadType == 1);
                        return {
                            id: x.id,
                            fileUrl: f ? f.fileUrl : '',
                            title: x.title,
                            price: x.price,
                            classId:x.classId,
                            measureArea: x.measureArea,
                            year: x.years,
                            area: x.area
                        }
                    });
                })
			},
            async getDisplayList() {
                if(this.dict.newsDisplay.length > 0) {
                    let d = this.dict.newsDisplay.find(x=> x.text == '热门图文');
                    await this.$http.post(this.$api.news.getList, { param: { showMode: d.id}, size: 5, current: 1})
                    .then(res => {
                        d.thumbList = res.result.records;
                    })
                    this.displayList.push(d);

                    let d2 = {
                        id: 4,
                        text: '最新政策',
                        thumbList: []
                    }
                    await this.$http.post(this.$api.news.getList, { param: { contentType: d2.id, isThumbnail: 1}, size: 1, current: 1})
                    .then(res => {
                        d2.thumbList = res.result.records.map(x=> {
                            return {
                                title: x.title,
                                content: x.content.replace(/<[^>]*>/g,''),
                                id: x.id,
                                thumbnail: x.thumbnail
                            }
                        });
                    })

                    await this.$http.post(this.$api.news.getList, { param: { contentType: d2.id, isThumbnail: 0}, size: 7, current: 1})
                    .then(res => {
                        d2.list = res.result.records;
                    })
                    this.displayList.push(d2);

                    let d3 = {
                        id: 8,
                        text: '流转资讯',
                        thumbList: []
                    }
                    await this.$http.post(this.$api.news.getList, { param: { contentType: d3.id, isThumbnail: 1}, size: 1, current: 1})
                    .then(res => {
                        d3.thumbList = res.result.records.map(x=> {
                            return {
                                title: x.title,
                                content: x.content.replace(/<[^>]*>/g,''),
                                id: x.id,
                                thumbnail: x.thumbnail
                            }
                        });
                    })

                    await this.$http.post(this.$api.news.getList, { param: { contentType: d3.id, isThumbnail: 0 }, size: 7, current: 1})
                    .then(res => {
                        d3.list = res.result.records;
                    })
                    this.displayList.push(d3);
                }
            },
            getNeedList() {
                this.$http.post(this.$api.supply.getNeedsList, {param:{},
					sortBy:{},
					size: 15,
					current: 1}).then(res => {
					if(res.code == 1) {
						this.needList = res.result;
					}
				})
            },
            onChooseNeedsArea(a) {
                this.tools.areaValue =
                    a.province.name +
                    (a.city.name == "市辖区" ? "" : " " + a.city.name) +
                    " " +
                    a.area.name;
                this.tools.area = a;
                this.tools.isAreaShow = false;
            },
            onSwipeChange(i) {
                this.autoplay = 5000;
            },
            onPrev() {
                this.autoplay = 0;
                this.$refs.swipe[0].prev();
            },
            onNext() {
                this.autoplay = 0
                this.$refs.swipe[0].next();
            },
            onUseLandChange(u) {
                this.tools.useLand = u;
            },
            scrollToBottom(key) {
                var m = this.$el.querySelector(key);
                this.current = parseInt(key.replace('#speakers',''));
                if(key == '#speakers0') {
                    document.body.scrollTop = 0; // chrome
                    document.documentElement.scrollTop = 0; // firefox
                } else {
                    document.body.scrollTop = m.offsetTop -60; // chrome
                    document.documentElement.scrollTop = m.offsetTop-60; // firefox
                }
            },
            handleScroll(){
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
                var anchorOffset0 = this.$el.querySelector('#speakers0').offsetTop - 80;
                var anchorOffset1 = this.$el.querySelector('#speakers1').offsetTop - 80;
                var anchorOffset2 = this.$el.querySelector('#speakers2').offsetTop - 80;
                var anchorOffset3 = this.$el.querySelector('#speakers3').offsetTop - 80;
                var anchorOffset4 = this.$el.querySelector('#speakers4').offsetTop - 80;
                if(scrollTop>anchorOffset0&&scrollTop<anchorOffset1){
                　　this.current = 0
                }
                if(scrollTop>anchorOffset1&&scrollTop<anchorOffset2){
                　　this.current = 1
                }
                if(scrollTop>anchorOffset2&&scrollTop<anchorOffset3){
                　　this.current = 2
                }
                if(scrollTop>anchorOffset3&&scrollTop<anchorOffset4){
                　　this.current = 3
                }
                if(scrollTop>anchorOffset4){
                　　this.current = 4
                }
            },
            onFindNeeds() {
                let url = "";
                if (this.tools.area) {
                    url += "province=" + this.tools.area.province.code;
                    url += "&city=" + this.tools.area.city.code;
                    url += "&area=" + this.tools.area.area.code;
                }
                if (this.tools.useLand) {
                    url +=
                    (url == "" ? "" : "&") + "classid=" + this.tools.useLand.class.id;
                    url += "&childclassid=" + this.tools.useLand.childClass.id;
                }
                if (
                    this.tools.useLand != null &&
                    this.metreArray.includes(this.tools.useLand.class.text)
                ) {
                    if (this.tools.areaRangeP) {
                    url += (url == "" ? "" : "&") + "arp=" + this.tools.areaRangeP.id;
                    }
                } else {
                    if (this.tools.areaRangeM) {
                    url += (url == "" ? "" : "&") + "arm=" + this.tools.areaRangeM.id;
                    }
                }

                if (this.tools.typeId == 1) {
                    window.open("/#/user/land/add?" + url, "_blank");
                } else {
                    window.open("/#/user/needs/add?" + url, "_blank");
                }
            },
        },
        created() {
            this.getResembleLand();
            this.getNeedList();
            this.getDisplayList();
        }
    }
</script>

<style scoped>

.section-header {
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 20px;
}

.section-header::before {
  content: '';
  position: absolute;
  display: block;
  width: 60px;
  height: 5px;
  background: #00cc00;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-header h2 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.section-header p {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #9195a2;
}

.section-with-bg {
  background-color: #f6f7fd;
}

#speakers {
  padding: 60px 0 30px 0;
}

#speakers .speaker {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.screen .active {
  background: #28a745;
  color: #ffffff;
  border-radius: 6px;
}

.news-title {
    position:absolute;
    bottom: 7px;
    left:0px; 
    background: rgba(0,0,0,.4);
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
}

.nav-header {
    width: 100%; 
    height: 76px;
    background-image: linear-gradient(90deg,#18b566 ,#00cc00);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
}
.nav-header .item{
    padding:10px;
    border-radius: 4px;
    text-align: center;
    color:#ffffff;
    cursor: pointer;
}
.nav-header .item:hover, .nav-header .item.active{
    background-color:white;
    border-radius: 4px;
    color:#00cc00
}

.tag {
    width: 96px;
    height: 96px;
    background-color: #18b566;
    border-radius: 8px;
    justify-content: center; 
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    cursor: pointer;
    color: #ffffff;
}

.tag:hover {
    border:2px #18b566 solid;
    background-color: #ffffff;
    color: #18b566;
}

.tag:first-child {
    margin-left: 0px;
}

.tag-item {
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    box-shadow: 0 2px 16px 0 #18b566;
    border-radius: 100%;
}

.tag:hover .tag-item {
    background-color: #18b566;
    box-shadow: none;
}

.tag-item-image {
    width: 48px;
    height: 48px;
    background-size: 36px 36px;
    background-position:center;
    background-repeat: no-repeat;
}

.tag-item-image.yu {
    background-image: url("~@/assets/icon/yu.svg");
}
.tag:hover .tag-item-image.yu {
    background-image: url("~@/assets/icon/yu-hover.svg");
}
.tag-item-image.zhu {
    background-image: url("~@/assets/icon/zhu.svg");
}
.tag:hover .tag-item-image.zhu {
    background-image: url("~@/assets/icon/zhu-hover.svg");
}
.tag-item-image.yumi {
    background-image: url("~@/assets/icon/yumi.svg");
}
.tag:hover .tag-item-image.yumi {
    background-image: url("~@/assets/icon/yumi-hover.svg");
}
.tag-item-image.dasuan {
    background-image: url("~@/assets/icon/dasuan.svg");
}
.tag:hover .tag-item-image.dasuan {
    background-image: url("~@/assets/icon/dasuan-hover.svg");
}
.tag-item-image.taozi {
    background-image: url("~@/assets/icon/taozi.svg");
}
.tag:hover .tag-item-image.taozi {
    background-image: url("~@/assets/icon/taozi-hover.svg");
}
.tag-item-image.ji {
    background-image: url("~@/assets/icon/ji.svg");
}
.tag:hover .tag-item-image.ji {
    background-image: url("~@/assets/icon/ji-hover.svg");
}
.tag-item-image.guangfuyongdi {
    background-image: url("~@/assets/icon/guangfu.svg");
}
.tag:hover .tag-item-image.guangfuyongdi {
    background-image: url("~@/assets/icon/guangfu-hover.svg");
}
.tag-item-image.yingtao {
    background-image: url("~@/assets/icon/yingtao.svg");
}
.tag:hover .tag-item-image.yingtao {
    background-image: url("~@/assets/icon/yingtao-hover.svg");
}

.tag-item-image.huolongguo {
    background-image: url("~@/assets/icon/huolongguo.svg");
}
.tag:hover .tag-item-image.huolongguo {
    background-image: url("~@/assets/icon/huolongguo-hover.svg");
}
.tag-item-image.qiukui {
    background-image: url("~@/assets/icon/qiukui.svg");
}
.tag:hover .tag-item-image.qiukui {
    background-image: url("~@/assets/icon/qiukui-hover.svg");
}
.tag-item-image.shuidao {
    background-image: url("~@/assets/icon/shuidao.svg");
}
.tag:hover .tag-item-image.shuidao {
    background-image: url("~@/assets/icon/shuidao-hover.svg");
}
.tag-item-image.putao {
    background-image: url("~@/assets/icon/putao.svg");
}
.tag:hover .tag-item-image.putao {
    background-image: url("~@/assets/icon/putao-hover.svg");
}
.tag-item-image.tu {
    background-image: url("~@/assets/icon/tu.svg");
}
.tag:hover .tag-item-image.tu {
    background-image: url("~@/assets/icon/tu-hover.svg");
}
.tag-item-image.hetao {
    background-image: url("~@/assets/icon/hetao.svg");
}
.tag:hover .tag-item-image.hetao {
    background-image: url("~@/assets/icon/hetao-hover.svg");
}
.tag-item-image.lanmei {
    background-image: url("~@/assets/icon/lanmei.svg");
}
.tag:hover .tag-item-image.lanmei {
    background-image: url("~@/assets/icon/lanmei-hover.svg");
}
.tag-item-image.huajiao {
    background-image: url("~@/assets/icon/huajiao.svg");
}
.tag:hover .tag-item-image.huajiao {
    background-image: url("~@/assets/icon/huajiao-hover.svg");
}

.land-nav{
    height: 40px;
    background: #dbf1e1;
    margin-bottom: 20px;
}

.land-nav ul li {
    width: 158px;
    height: 40px;
    float: left;
    position: relative;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
}


.land-nav ul li.active:before {
	width:0;
	height:0;
	content:"";
	border-left:10px solid transparent;
	border-right:10px solid transparent;
	border-top:10px solid #18b566;
	position:absolute;
	bottom:-10px;
	left:43%
}
.land-nav ul li.active {
	color:#fff;
	background-image:-webkit-linear-gradient(0deg,#19be6b 0,#00cc00 100%);
	background-image:linear-gradient(90deg,#19be6b 0,#00cc00 100%);
	-webkit-transition:all .2s;
	transition:all .2s
}

.land-nav ul li.active a {
	color:#fff;
}

</style>